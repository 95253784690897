import { ARTSTORE_PUBSUB_ENUMS } from '../../constants';

const createCartForm = (openPersistentPopup, compId) => {
  openPersistentPopup(
    'https://progallery.wix.com/cart-form.html?isStore=true',
    {
      width: '0%',
      height: '100%',
      position: {
        origin: 'FIXED',
        placement: 'CENTER',
      },
      theme: 'BARE',
    },
    compId,
  );
};

const getArtStoreApi = (pubSub, openPersistentPopup, compId, setProps) => {
  const sendOOImessage = (eventName, data, isPersistent) =>
    pubSub.publish(
      ARTSTORE_PUBSUB_ENUMS.OOI_MESSAGE,
      {
        type: eventName,
        payload: data,
      },
      isPersistent,
    );
  return {
    initCart: () => {
      createCartForm(openPersistentPopup, compId);
      pubSub.subscribe(ARTSTORE_PUBSUB_ENUMS.CART_ITEMS_CHANGE, data => {
        setProps({
          cartItems: data.data.cartItems,
          cartItemsCount: data.data.cartItemsCount,
        });
      });
      pubSub.subscribe(ARTSTORE_PUBSUB_ENUMS.BI_DATA_CHANGED, data => {
        setProps({
          biToken: data.data.biToken,
          AnonymousUserId: data.data.AnonymousUserId,
        });
      });
    },
    refreshCartItems: connectedProviderId =>
      sendOOImessage(
        ARTSTORE_PUBSUB_ENUMS.REFRESH_OOI_CART,
        { connectedProviderId },
        true,
      ),
    showCart: () => sendOOImessage(ARTSTORE_PUBSUB_ENUMS.SHOW_CART, {}, false),
    onCheckout: () => pubSub.publish(ARTSTORE_PUBSUB_ENUMS.CHECKOUT, {}, false),
    addToCart: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.ADD_TO_CART, data, false),
    openProviderModal: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.OPEN_PROVIDER_MODAL, data, false),
    updateCartItem: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.UPDATE_CART_ITEM, data, false),
    clearCart: () =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.CLEAR_CART, {}, false),
    removeFromCart: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.REMOVE_FROM_CART, data, false),
    initBiData: () =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.REFRESH_BI_DATA, {}, true),
  };
};

export default getArtStoreApi;
